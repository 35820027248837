
import { defineComponent } from 'vue';
import FileComponent from './FileComponent.vue';

export default defineComponent({
  components: {
    FileComponent,
  },
  methods: {
    openFile(url: string) {
      window.open(url, '_blank');
    },
  },
  name: 'ListComponent',
  props: ['files'],
});
