
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FileComponent',
  props: ['file'],
  methods: {
    getIcon() {
      if (this.file.fileType === 'application/pdf') {
        return 'fa-solid fa-file-pdf';
      }
      if (this.file.fileType === 'video/mp4') {
        return 'fa-solid fa-file-video';
      }
      return 'fa-solid fa-file';
    },
  },
});
